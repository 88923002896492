<template>
    <div class="PowerSetting">
        <div v-if="show" class="settingBox">
            <div class="powerItemBox">
                <div class="powerItem">
                    <div class="name">{{powerItem[0].funName}}</div>
                    <van-switch :value="powerItem[0].rightValue==1" @change="powerItemChnage(0,powerItem[0].rightValue==1?0:1)" />
                </div>
            </div>
            <div class="info">目录管理员具有最大权限，可对本共享目录设置权限，修改目录名和目录下的文件夹及文档一切权限。</div>
            <div class="title ">基础权限</div>
            <ul class="powerItemBox ">
                <template v-for="(item,index) in powerItem ">
                    <li v-if="index!=0 " :key="index " class="powerItem " :class="{ 'powerItem van-hairline--bottom':index!=powerItem.length-1} ">
                        <div class="name ">{{powerItem[index].funCode=='DC0006'?'下载文档':powerItem[index].funName}}</div>
                        <van-switch :value="powerItem[index].rightValue==1 " @change="powerItemChnage(index,powerItem[index].rightValue==1?0:1)" />
                    </li>
                </template>
            </ul>

        </div>
        <van-button :loading='isSaving' size="large" class="btmBtn" @click="savePower()">确定</van-button>
    </div>
</template>

<script>
import titleMixin from '@/mixin/title'
export default {
    name: 'PowerSetting',
    mixins: [titleMixin],
    title: '分配权限',
    data() {
        return {
            isSaving: false,
            show: false,
            powerItem: [],
            changeMap: new Map()
        }
    },
    mounted() {
        this.pageInit()
    },
    methods: {

        powerItemChnage(index, val) {
            index = parseInt(index)
            let item = this.powerItem[index]
            if (item.rightValue == val) {
                return false
            }
            item.rightValue = val
            let rightItem = {
                'rightValue': item.rightValue,
                'objectId': item.objectId,
                'objectType': item.objectType,
                'funType': item.funType,
                'funCode': item.funCode
            }
            let key = `${item.objectType}&${item.objectId}&${item.funCode}`
            if (this.changeMap.has(key)) {
                this.changeMap.delete(key)
            } else {
                this.changeMap.set(key, rightItem)
            }

            /* 大量选项显示上的逻辑 */
            /* 目录管理员选中时候将其他的都选上 */
            if (index == 0 && val == 1) {
                this.powerItem.forEach((element, idx) => {
                    this.powerItemChnage(idx, 1)
                })
            }

            /* 非目录管理员选项取消时候移除目录管理员 */
            if (index != 1 && val == 0) {
                this.powerItemChnage(0, 0)
            }

            /* 2、3、4、5分别对应文档的上传、修改、删除、导出 */
            let docQxArr = [2, 3, 4, 5]
            if (docQxArr.includes(index) && val == 1) {
                this.powerItemChnage(1, 1)
            }
            /* 读取文档选项取消时候  文档的几个操作都取消 */
            if (index == 1 && val == 0) {
                docQxArr.forEach(element => {
                    this.powerItemChnage(element, 0)
                })
            }
        },
        async savePower() {
            try {
                let rightsArray = [...this.changeMap.values()]
                if (rightsArray.length == 0) {
                    this.$toast.fail('没有更改')
                    return false
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_folderRights
                let data = {
                    folderId: this.powerItem[0].folderId,
                    rightsArray
                }
                this.isSaving = true
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('设置成功')
                    this.changeMap.clear()
                } else {
                    this.$toast.fail('出了点问题，请重试')
                }
            } catch (error) {
                console.log(error)
            }
            this.isSaving = false
        },
        pageInit() {
            this.Global.utils.rightMenu.clearMenu()
            let { row } = this.$route.params
            if (row && row.length > 0) {
                this.powerItem = [].concat(row)
                this.show = true
            } else {
                this.$router.back()
            }
        },
        beforeRouteLeave(to, from, next) {
            this.show = false
            this.powerItem = []
            this.changeMap = new Map()
            next()
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/document/powersetting') {
                this.pageInit()
            }
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
